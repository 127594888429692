import {
  hydrogen_drone_cover,
  hydrogen_energy_cover,
  wind_power_cover,
  wind_power_cover_2,
} from "../assets/images/images";

const ServicesData = [
  {
    title: "Hydrogen Energy",
    image: hydrogen_energy_cover,
    navigate: "/hydrogen-energy",
    description:
      "Utilizing hydrogen as a clean and renewable energy source involving the production of hydrogen through the water electrolysis method, where water is split into hydrogen and oxygen using electricity. The generated hydrogen can be used for various applications, such as fuel cells to produce electricity or as a clean fuel for transportation.",
  },
  {
    title: "Small wind power",
    image: wind_power_cover,
    navigate: "/small-wind-power",
    description:
      "This service centers around the generation of electricity through small wind turbines. Small wind power systems harness the power of wind to convert it into electrical energy. These systems are typically used for localized power generation, such as in residential, commercial, or rural areas. The service may include the installation, maintenance, and optimization of small wind turbines..",
  },
  {
    title: "Smart Farm",
    image: wind_power_cover_2,
    navigate: "/smart-farm",
    description:
      "Small wind power generation combined with advanced technologies help create sustainable and efficient farming practices. It involves the integration of renewable energy sources, such as small wind turbines, to power various agricultural operations. Additionally, smart farm technologies can include automated monitoring systems, data analytics, and precision agriculture techniques to optimize resource usage, improve crop yields, and reduce environmental impact",
  },
  {
    title: "Hydrogen drone",
    image: hydrogen_drone_cover,
    navigate: "/hydrogen-drone",
    description:
      "Hydrogen drones utilize hydrogen fuel cells as their energy source, offering longer flight times and reduced environmental impact compared to traditional battery-powered drones. These drones can be used for aerial inspections, surveillance, mapping, agricultural monitoring, and other tasks that require extended flight durations.",
  },
];

const HydrogenCells = [
  {
    title: "Green Hydrogen",
    description:
      "This is the method of electrolyzing (water electrolysis) water (H2O). It is eco-friendly hydrogen that does not emit carbon in the production process.",
  },
  {
    title: "Blue hydrogen",
    description:
      "Hydrogen made incidentally in the process of making petrochemical processes or steel.",
  },
  {
    title: "Grey hydrogen",
    description:
      "It is produced from something like oil by extracting it from fossil fuels. Therefore, gray hydrogen is not considered an eco-friendly energy. Currently, gray hydrogen is about 90% of the total consumption.",
  },
];

const SmallWindData = [
  {
    title: "Minimize wind resistance",
    description:
      "Even in the same vertical type, due to the difference in area affected by the wind, other companies’ products are bent or damaged and have thermal errors, whereas this product is designed to traverse the flow of the wind, and thermal errors are minimal by minimizing damage.",
  },
  {
    title: "Power generation capable of breeze operation",
    description:
      "The PMG (permanent magnet) type generator is a generator composed of permanent magnets without gears and coils, and unlike other products, it is possible to operate in a fine wind.",
  },
  {
    title: "Regardless of the wind direction (360 degrees)",
    description:
      "In the case of other companies’ products, the amount of energy decreases in the case of a headwind, but our products increase further. Energy can be converted to wind in all directions",
  },
  {
    title: "Resolving noise by rotation",
    description:
      "Even in the same vertical type, due to the difference in area affected by the wind, other companies’ products are bent or damaged and have thermal errors, whereas this product is designed to traverse the flow of the wind, and thermal errors are minimal by minimizing damage.",
  },
  {
    title: "Excellent maintenance and structural stability",
    description:
      "Excellent durability and structural stability of the product through the blade structure of the double bonding method and anodizing treatment",
  },
  {
    title: "Double wing structure inside and outside",
    description:
      "The structure of the inner wing of SAVONIUS BLADES enabling gentle wind driving and the outer wing of DARIUS BLADES realizing high efficiency",
  },
  {
    title: "Products with the advantages of lift and scent type",
    description:
      "Starts operation with little wind. Even if the wind stops, there is no rotational resistance due to no-load, so it does not stop for a long time and has excellent energy recovery when the wind blows again.",
  },
  {
    title: "High efficiency by accelerating the retreat angle",
    description:
      "More energy mass is generated even at the same wind speed (in case of other products, it is proportional to the wind speed)",
  },
  {
    title: "Double wing structure inside and outside",
    description:
      "The structure of the inner wing of SAVONIUS BLADES enabling gentle wind driving and the outer wing of DARIUS BLADES realizing high efficiency",
  },
];

export { ServicesData, HydrogenCells, SmallWindData };

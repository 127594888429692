import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

const Container = ({ children, selected, nested }) => {
  return (
    <div>
      <Navbar selected={selected} nested={nested} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Container;

import { ThemeButton } from "../components";

const ServicesCard = ({ title, image, onClick, description, noHeight }) => {
  return (
    <div
      className={`bg-white rounded-xl p-6 flex flex-col justify-between ${
        !noHeight && "md:min-h-[600px] lg:min-h-[700px]"
      }`}
    >
      <img src={image} alt="" width={"100%"} />
      <div
        className={`flex items-center flex-col text-center ${
          !noHeight && "md:h-72"
        } justify-around mt-4`}
      >
        <p className="text-primary text-5xl">{title}</p>
        <p className="inter mt-2 text-primary mb-6">{description}</p>
        <ThemeButton
          onClick={onClick}
          content={"Learn more"}
          onlyBorder
          darkBorder
        />
      </div>
    </div>
  );
};

export default ServicesCard;

import React, { useState, Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { menu_icon } from "../../assets/icons/icons";
import { reellogo } from "../../assets/images/images";
import ThemeButton from "../themeButton/themeButton";
import { DownOutlined } from "@ant-design/icons";
import { Popover, Transition } from "@headlessui/react";

const MobView = ({ onClick, services, reelcause, selected }) => {
  const [options, setOptions] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-6 bg-primary lg:hidden block sticky top-0 z-10">
      <div className="flex items-center justify-between">
        <img
          className="cursor-pointer"
          onClick={onClick}
          src={reellogo}
          alt=""
          style={{
            width: "163px",
          }}
        />
        <img
          src={menu_icon}
          onClick={() => setOptions(!options)}
          alt=""
          className="cursor-pointer"
        />
      </div>
      {options && (
        <div className="flex flex-col items-center mt-5">
          <NavLink
            className={`${
              selected === "about"
                ? "text-themeGreen"
                : "text-themeWhite hover:text-themeGreen"
            } my-2 inter`}
            to="/about"
          >
            About
          </NavLink>
          <Popover className="relative my-2 inter">
            <Popover.Button
              className={`${
                selected === "services"
                  ? "text-themeGreen"
                  : "text-themeWhite hover:text-themeGreen"
              } flex items-center gap-x-1 text-sm font-semibold leading-6`}
            >
              Product
              <DownOutlined />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-16 top-full z-10 mt-3 w-48 overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {services.map((item) => (
                    <div
                      key={item.name}
                      className="cursor-pointer group relative flex items-center rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      onClick={() => navigate(item.navigate)}
                    >
                      <div className="flex-auto">
                        <p className={`inter`}>{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative my-2 inter">
            <Popover.Button
              className={`${
                selected === "reelcause"
                  ? "text-themeGreen"
                  : "text-themeWhite hover:text-themeGreen"
              } flex items-center gap-x-1 text-sm font-semibold leading-6`}
            >
              Reelcause R&D
              <DownOutlined />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-10 top-full z-10 mt-3 w-48 overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {reelcause.map((item) => (
                    <div
                      key={item.name}
                      className="cursor-pointer group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      onClick={() => navigate(item.navigate)}
                    >
                      <div className="flex-auto">
                        <p className={`inter`}>{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <a
            className={`${
              selected === "investor"
                ? "text-themeGreen"
                : "text-themeWhite hover:text-themeGreen"
            } my-2 inter cursor-pointer`}
            href="https://www.otcmarkets.com/stock/RCIT/overview"
            target="_blank"
          >
            Investor Relations
          </a>
          <a
            className={`${
              selected === "korea"
                ? "text-themeGreen"
                : "text-themeWhite hover:text-themeGreen"
            } my-2 inter cursor-pointer`}
            href="http://www.reelcausernd.kr/"
            target="_blank"
          >
            Korea Reelcause R&D
          </a>
        </div>
      )}
    </div>
  );
};

const Navbar = ({ selected }) => {
  const navigate = useNavigate();
  const services = [
    {
      name: "Hydrogen Energy",
      navigate: "/hydrogen-energy",
    },
    {
      name: "Hydrogen Drone",
      navigate: "/hydrogen-drone",
    },
    {
      name: "Small Wind Power",
      navigate: "/small-wind-power",
    },
    {
      name: "Smart Farm",
      navigate: "/smart-farm",
    },
  ];
  const reelcause = [
    {
      name: "P2G System",
      navigate: "/p2g-system",
    },
    {
      name: "The Hydrogen Method",
      navigate: "/hydrogen-method",
    },
  ];
  return (
    <>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-6 bg-primary hidden lg:flex items-center justify-between sticky top-0 z-10">
        <img
          className="cursor-pointer"
          onClick={() => navigate("/")}
          src={reellogo}
          alt=""
          style={{
            width: "163px",
          }}
        />
        <div className="flex">
          <NavLink
            className={`${
              selected === "about"
                ? "text-themeGreen"
                : "text-themeWhite hover:text-themeGreen"
            } mx-4 inter`}
            to="/about"
          >
            About
          </NavLink>
          <Popover className="relative mx-4 inter">
            <Popover.Button
              className={`${
                selected === "services"
                  ? "text-themeGreen"
                  : "text-themeWhite hover:text-themeGreen"
              } flex items-center gap-x-1 text-sm font-semibold leading-6`}
            >
              Product
              <DownOutlined />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {services.map((item) => (
                    <div
                      key={item.name}
                      className="cursor-pointer group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      onClick={() => navigate(item.navigate)}
                    >
                      <div className="flex-auto">
                        <p className={`inter`}>{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative mx-4 inter">
            <Popover.Button
              className={`${
                selected === "reelcause"
                  ? "text-themeGreen"
                  : "text-themeWhite hover:text-themeGreen"
              } flex items-center gap-x-1 text-sm font-semibold leading-6`}
            >
              Reelcause R&D
              <DownOutlined />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {reelcause.map((item) => (
                    <div
                      key={item.name}
                      className="cursor-pointer group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      onClick={() => navigate(item.navigate)}
                    >
                      <div className="flex-auto">
                        <p className={`inter`}>{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <a
            className={`${
              selected === "investor"
                ? "text-themeGreen"
                : "text-themeWhite hover:text-themeGreen"
            } mx-4 inter cursor-pointer`}
            href="https://www.otcmarkets.com/stock/RCIT/overview"
            target="_blank"
          >
            Investor Relations
          </a>
          <a
            className={`${
              selected === "korea"
                ? "text-themeGreen"
                : "text-themeWhite hover:text-themeGreen"
            } mx-4 inter cursor-pointer`}
            href="http://www.reelcausernd.kr/"
            target="_blank"
          >
            Korea Reelcause R&D
          </a>
        </div>
        <div className="w-10"></div>
        {/* <ThemeButton
          className={"h-10"}
          content={<p style={{ marginTop: 1 }}>Contact us</p>}
        /> */}
      </div>
      <MobView
        onClick={() => navigate("/")}
        services={services}
        reelcause={reelcause}
        selected={selected}
      />
    </>
  );
};

export default Navbar;

import { Container } from "../../components/components";
import { drones, drone_2 } from "../../assets/images/images";
import ReactPlayer from "react-player";

const HydrogenDrone = () => {
  return (
    <Container selected={"services"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              Hydrogen Drone
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <p className="text-primary text-2xl md:text-5xl mt-5 md:mt-0">
          Development of commercial drone using hydrogen fuel cell-former Soviet
          Union technology alliance
        </p>
        <div className="mt-8">
          <ReactPlayer
            width={"100%"}
            height={400}
            url="https://www.youtube.com/watch?v=ExEAXl3oHpo&t=2s"
            controls={true}
          />
        </div>
        <p className="mt-8 text-primary inter">
          It has the longest running record of 2.5 hours as a self-test in 2015
          Currently, it is being developed and upgraded to urban aeronautical
          mobility, such as for agriculture and cargo transportation, for
          lifesaving, for forest fire monitoring, etc.
        </p>
        <div className="mt-8">
          <ReactPlayer
            width={"100%"}
            height={400}
            url="https://www.youtube.com/watch?v=t8F3pUmV6F0"
            controls={true}
          />
        </div>
        <div className="mt-8 flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 md:mr-10">
            <p className="text-primary text-center md:text-start text-4xl md:text-6xl">
              “ All of this is a story that requires a drone to stay afloat for
              a long time ”
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <img src={drones} alt="" width={"100%"} height={"auto"} />
          </div>
        </div>
        <p className="mt-16 text-primary text-5xl text-center">
          Specifications
        </p>
        <div className="mt-8 flex flex-col md:flex-row items-center justify-between">
          <div className="mt-4 md:mt-0">
            <img src={drone_2} alt="" width={"100%"} height={"auto"} />
          </div>
          <div className="w-full md:w-1/2 md:ml-10">
            <ul className="list-disc text-primary inter ml-6 mt-4 leading-8">
              <li>
                Main specifications of commercial drones using hydrogen fuel
                cells
              </li>
              <li>Category ContentRated operating voltage 38V30A</li>
              <li>max before rated operationRated power</li>
              <li>1,000-1,150WPeak power up to 3kW12 liters of fuel</li>
              <li>tank. Cylinder 300 atm.,4.8 hours battery lifeFuel cell</li>
              <li>weight 7kgEnergy consumption 680 W * h / kg;</li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HydrogenDrone;

import { Container } from "../../components/components";
import {
  hydrogen_forcast,
  neutron,
  p2g_system,
  plasma,
} from "../../assets/images/images";

const P2gSystem = () => {
  return (
    <Container selected={"reelcause"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              P2G System
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <p className="text-primary text-2xl md:text-5xl mt-5 md:mt-0 text-center">
          P2G (Power to Gas) System
        </p>
        <p className="mt-8 text-primary inter">
          Power generation using hydrolysis chamber (H2 100% + H2O = hydrolysis
          chamber) Turbine power generation is a CO2-free power plant that does
          not emit any greenhouse gases. In principle, production is as follows.
        </p>
        <img
          src={p2g_system}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        <div className="mt-12 flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 md:mr-10">
            <p className="text-primary text-center md:text-start text-4xl md:text-6xl">
              Global hydrogen energy consumption is expected to reach about 22%
              of the total by 2050 thanks to carbon neutrality in 2050.
            </p>
          </div>
          <div className="mt-4 md:mt-0 md:w-1/2">
            <img src={hydrogen_forcast} alt="" width={"100%"} height={"auto"} />
          </div>
        </div>
        <p className="text-primary text-2xl md:text-5xl mt-12">
          The safety of hydrogen
        </p>
        <p className="mt-8 text-primary inter">
          According to the advice of experts, hydrogen is evaluated to be safer
          than city gas It is a structure of a different dimension that is
          generally incomparable as nuclear reactions take place in the phase.
        </p>
        <img
          src={neutron}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        <p className="mt-12 text-primary inter">
          Hydrogen has one proton, deuterium has one proton and one neutron, and
          tritium has one proton and two neutrons.
        </p>
        <img
          src={plasma}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        <p className="mt-8 text-primary inter">
          Hydrogen is the lightest gas (14 times that of air) among all elements
          on Earth, and experts say that it is safer than LPG or LNG because it
          is blown away when exposed to air. Since LPG and LNG are heavier than
          air, they are stored in basements or low places, so there is a risk of
          explosion at any time depending on external influences.
        </p>
      </div>
    </Container>
  );
};

export default P2gSystem;

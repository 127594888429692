import {
  Container,
  ServicesCard,
  ThemeButton,
} from "../../components/components";
import {
  cloud,
  sun,
  filled_star,
  filled_star_2,
  right_arrow_icon,
  arrow_icon,
} from "../../assets/icons/icons";
import { profile_image } from "../../assets/images/images";
import { Col, Input, Row } from "antd";
import { ServicesData } from "../../constants/constants";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  return (
    <Container selected={"about"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              About us
            </p>
          </div>
        </div>
        {/* <div className="w-full h-auto">
          <div className="flex justify-center relative top-24">
            <img
              src={about_cover}
              alt=""
              width={"85%"}
              height={"auto"}
              className="z-10"
            />
          </div>
          <div className="flex justify-between">
            <img src={many_clouds} alt="" />
            <img src={many_clouds} alt="" />
          </div>
          <div className="relative">
            <img
              src={grass_light}
              alt=""
              width={"100%"}
              height={"auto"}
              className="absolute top-5"
            />
          </div>
          <div>
            <img src={grass_dark} alt="" width={"100%"} height={"auto"} />
          </div>
        </div> */}
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <img src={profile_image} alt="" />
          </Col>
          <Col lg={18} md={10} sm={24} xs={24} className="mb-6">
            <div className="flex items-start justify-between mt-7 md:mt-0">
              <p className="text-5xl lg:text-7xl text-primary w-full lg:w-3/4">
                Greeting From Our CEO
              </p>
              <img src={filled_star} alt="" className="mt-3 lg:mt-7" />
            </div>
            <p className="inter mt-5 leading-6 w-full lg:w-3/4 text-primary">
              It is a compound word of the reel and cause of the compass model.
              It means that M&A (recruitment) of a reasoned item or talent is
              suggested to provide a future-oriented direction.
            </p>
          </Col>
        </Row>

        <div className="mt-24">
          <p className="text-3xl lg:text-7xl text-primary w-full lg:w-3/4">
            Outline-The 4th Industrial
          </p>
          <p className="text-3xl lg:text-7xl text-primary w-full lg:w-3/4">
            Revolution and Future Strategy
          </p>
          <p className="inter mt-5 leading-6 w-full text-primary">
            REELCAUSE.INC, an American NASDAQ OTC company, is trying to enter
            the global market through innovative green hydrogen production
            technology and hydrogen drones based on the technology, hydrogen
            vehicle charging business, and zero-energy buildings based on
            hydrogen heat-combined power plants. The 4th industrial revolution
            under the goal of being an innovative company in 21C new and
            renewable energy by reinforcing global market leadership and
            reinforcing technological capabilities based on eco-friendly and
            professional technology We aim to develop as a key growth engine in
            the future.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-around mt-12 md:mt-24">
          <img src={filled_star} alt="" className="mb-10 md:mb-0" />
          <div className="flex flex-col items-center">
            <p className="text-primary text-6xl -mt-5">Target Products</p>
          </div>
          <img src={filled_star} alt="" className="mt-5 md:mt-0" />
        </div>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 45 }}>
          {ServicesData.map((e, i) => {
            return (
              <Col lg={12} md={12} sm={24} xs={24} className="mt-10">
                <ServicesCard
                  noHeight
                  onClick={() => navigate(e.navigate)}
                  title={e.title}
                  image={e.image}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
};

export default About;

import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { logoPng } from "../../assets/images/images";

const Footer = () => {
  return (
    <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:pt-10 bg-white">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col lg={9} md={24} sm={24} xs={24} className="mb-6">
          <img src={logoPng} alt="" width={200} />
          <p className="mt-6 text-primary inter">
            REELCAUSE R&D contributes to the development of new alternative
            energy rather than passively addressing global climate and
            environmental issues in partnership with global NGOs, governments,
            and corporations to protect the earth’s environment, and actively
            solves environmental problems by applying them to reality. It serves
            as a global environmental center.
          </p>
        </Col>
        <Col xl={3} lg={4} md={6} sm={8} xs={24} className="mb-6">
          <div className="flex flex-col text-primary text-lg">
            <NavLink to="#" className="text-2xl">
              Contact
            </NavLink>

            <a href="mailto:hydorgentech@reelcausernd.com">
              hydrogentech@reelcausernd.com
            </a>
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={8} xs={24} className="mb-6">
          <div className="flex flex-col text-primary text-lg">
            <p className="text-2xl">Product</p>
            <NavLink
              to="/hydrogen-energy"
              className="inter text-base font-semibold mt-1"
            >
              Hydrogen Energy
            </NavLink>
            <NavLink
              to="/small-wind-power"
              className="inter text-base font-semibold mt-1"
            >
              Small Wind Power
            </NavLink>
            <NavLink
              to="/smart-farm"
              className="inter text-base font-semibold mt-1"
            >
              Smart Farm
            </NavLink>
            <NavLink
              to="/hydrogen-drone"
              className="inter text-base font-semibold mt-1"
            >
              Hydrogen Drone
            </NavLink>
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={8} xs={24} className="mb-6">
          <div className="flex flex-col text-primary text-lg">
            <p className="text-2xl">Reelcause R&D</p>
            <NavLink
              to="/p2g-system"
              className="inter text-base font-semibold mt-1"
            >
              P2G System
            </NavLink>
            <NavLink
              to="/hydrogen-method"
              className="inter text-base font-semibold mt-1"
            >
              The Hydrogen Method
            </NavLink>
            <NavLink className="inter text-base font-semibold mt-1">
              Processed Pattents
            </NavLink>
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={8} xs={24} className="mb-6">
          <div className="flex flex-col text-primary text-lg">
            <a
              className="text-2xl"
              href="https://www.otcmarkets.com/stock/RCIT/overview"
              target="_blank"
            >
              Investor Relations
            </a>
          </div>
        </Col>
        <Col xl={3} lg={4} md={6} sm={8} xs={24} className="mb-6">
          <div className="flex flex-col text-primary text-lg">
            <a
              className="text-2xl"
              href="http://www.reelcausernd.kr/"
              target="_blank"
            >
              Korea Reelcause R&D
            </a>
          </div>
        </Col>
      </Row>
      <div
        style={{ border: "1px solid #184E51" }}
        className="my-4 opacity-20"
      ></div>
      <div className="text-primary inter text-sm flex flex-col lg:flex-row items-center justify-between">
        <p>© 2023 . REELCAUSE R&D, All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;

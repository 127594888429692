import { Container } from "../../components/components";
import { drone_2, smart_farm } from "../../assets/images/images";
import { HydrogenCells } from "../../constants/constants";

const SmartFarm = () => {
  return (
    <Container selected={"services"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              Smart Farm
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <p className="text-primary text-2xl md:text-5xl mt-5 md:mt-0">
          Attachment: Introduction of hydrogen fuel cell related technology and
          small wind power technology
        </p>
        <p className="mt-8 text-primary inter">
          As a non-metallic element, it is the lightest element present on the
          earth. It is a colorless, tasteless, and odorless gas, and hydrogen is
          an element that is regarded as an important non-polluting energy
          source to replace coal and petroleum because it does not emit
          pollutants even if it is burned. Periodic Table of the Elements As the
          symbol of the element 1, there is one quantum in the nucleus and no
          neutrons. It exists as a hydrogen molecule (H2) in which two atoms are
          bonded and is composed of organic compounds such as water, propane,
          methanol, and natural gas. Hydrogen is the most common substance in
          the universe, and it is 14 times lighter than air, so it disperses
          quickly in the atmosphere and has little possibility of exploding, and
          its autoignition point is 575 degrees, higher than gasoline (500
          degrees). There are many ways to produce hydrogen, specifically
          expressing what was mentioned above, and there are many ways to use
          the produced hydrogen.
        </p>
        <img
          src={smart_farm}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        {HydrogenCells.map((e, i) => {
          return (
            <div className="flex mt-8">
              <div className="border-2 border-primary"></div>
              <div className="ml-5">
                <p className="text-primary text-2xl mb-4">
                  {i + 1} {e.title}:
                </p>
                <p className="inter text-primary">{e.description}</p>
              </div>
            </div>
          );
        })}
        <p className="mt-8 text-primary inter">
          That’s it. Method of decomposing natural gas at high temperature and
          high pressure (reformed hydrogen) In this water electrolysis
          technology, the most ideal and efficient way to create green hydrogen
          is to use the surplus power of renewable energy (solar power, wind
          power) to receive electrolysis (electrolysis). If hydrogen is produced
          and stored through ), the fuel cell can be used to obtain electricity
          again regardless of time and place, and hydrogen can have the meaning
          of sending electricity if it is delivered to another place through
          logistics. Hydrogen is not just a fuel cell that generates
          electricity, but hydrogen is effective in storing large-capacity
          power. Since renewable energy is a big energy source in the future,
          hydrogen storage can be seen as the meaning of electricity storage.
        </p>
        <p className="text-primary text-2xl md:text-5xl mt-8 pb-2">
          Hydrogen related product development
        </p>
        {[
          "Hydrogen fuel cell power generation (household water electrolysis) hydrogen amplification system",
          "Air conditioner without outdoor unit by PCM heat storage method",
          "Aerospace (drone)",
          "Construction field (zero energy building)",
          "Container-Based Hydrogen Power Plant",
        ].map((e, i) => {
          return (
            <p className="text-primary mt-3 inter">
              {i + 1}) {e}
            </p>
          );
        })}
        <p className="mt-16 text-primary text-5xl text-center">
          Specifications
        </p>
        <div className="mt-8 flex flex-col md:flex-row items-center justify-between">
          <div className="mt-4 md:mt-0">
            <img src={drone_2} alt="" width={"100%"} height={"auto"} />
          </div>
          <div className="w-full md:w-1/2 md:ml-10">
            <ul className="list-disc text-primary inter ml-6 mt-4 leading-8">
              <li>
                Main specifications of commercial drones using hydrogen fuel
                cells
              </li>
              <li>Category ContentRated operating voltage 38V30A</li>
              <li>max before rated operationRated power</li>
              <li>1,000-1,150WPeak power up to 3kW12 liters of fuel</li>
              <li>tank. Cylinder 300 atm.,4.8 hours battery lifeFuel cell</li>
              <li>weight 7kgEnergy consumption 680 W * h / kg;</li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SmartFarm;

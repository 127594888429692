import { useState } from "react";
import { Modal, Row, Col, Input, message, Form, Spin } from "antd";
import { cross_icon } from "../../assets/icons/icons";
import { ThemeButton, ThemeDropdown } from "../components";
import ThemeInput from "../themeInput/themeInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "@emailjs/browser";

const items = [
  {
    label: <p className="inter">1st menu item</p>,
    key: "1",
  },
  {
    label: <p className="inter">2nd menu item</p>,
    key: "2",
  },
  {
    label: <p className="inter">3rd menu item</p>,
    key: "3",
  },
];

const PartnershipModal = ({ isModalOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const sendEmail = async (values) => {
    setLoading(true);
    await emailjs
      .send(
        "service_5zgzhcp",
        "template_tme4u8l",
        {
          first_name: values.first_name,
          last_name: values.last_name,
          company_name: values.company_name,
          email: values.email,
          website_url: values.website_url,
          phone: `+${values.phone}`,
          type_of_investment: values.type_of_investment,
          investment_size: values.investment_size,
          company_style: values.company_style,
          description: values.description,
        },
        "W7IdOcoVmf31DP8eW"
      )
      .then(
        (result) => {
          console.log(result.text);
          message.success("Email Sent!");
          setLoading(false);
          onClose();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          onClose();
        }
      );
  };
  return (
    <Modal
      open={isModalOpen}
      onCancel={onClose}
      footer={false}
      title={<p className="text-primary">PARTNERSHIP INQUIRY</p>}
      closeIcon={
        <div className="bg-themeLightRed h-6 w-6 rounded-full flex justify-center items-center">
          <img src={cross_icon} alt="" width={12} />
        </div>
      }
      width={700}
    >
      <div
        style={{ border: "1px solid rgba(0,0,0,.05)" }}
        ㅂ
        className="mb-2"
      ></div>
      <Form onFinish={sendEmail} name="normal_login" className="login-form">
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }} className="mt-6">
          <Col span={12}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: `Please input your First Name!`,
                },
              ]}
            >
              <ThemeInput
                name="first_name"
                placeholder={"First Name"}
                label={"First Name"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: `Please input your Last Name!`,
                },
              ]}
            >
              <ThemeInput
                name="last_name"
                placeholder={"Last Name"}
                label={"Last Name"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="company_name"
              rules={[
                {
                  required: true,
                  message: `Please input your Company Name!`,
                },
              ]}
            >
              <ThemeInput
                name="company_name"
                placeholder={"Enter company name"}
                label={"Company Name"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: `Please input your Email!`,
                },
              ]}
            >
              <ThemeInput
                name="email"
                placeholder={"Enter your email"}
                label={"Email"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="website_url"
              rules={[
                {
                  required: true,
                  message: `Please input Website URl!`,
                },
              ]}
            >
              <ThemeInput
                name="website_url"
                addonBefore={"http://"}
                placeholder={"www.untitledui.com"}
                label={"Company Website/URL"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <p className="inter text-primary mb-1">
              Phone
              <span>*</span>
            </p>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: `Please input your Number!`,
                },
              ]}
            >
              <PhoneInput
                inputStyle={{ width: "100%", backgroundColor: "#FAFAFA" }}
                country={"us"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="investment_size"
              rules={[
                {
                  required: true,
                  message: `Please input Size of Investment!`,
                },
              ]}
            >
              <ThemeInput
                name="investment_size"
                placeholder={"Typical Investment Size"}
                label={"Typical Investment Size"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="type_of_investment"
              rules={[
                {
                  required: true,
                  message: `Please input Type of Investment!`,
                },
              ]}
            >
              <ThemeInput
                name="type_of_investment"
                placeholder={"Typical Type of Investment"}
                label={"Typical Type of Investment"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="company_style"
              rules={[
                {
                  required: true,
                  message: `Please input Type of Investment!`,
                },
              ]}
            >
              <ThemeInput
                name="company_style"
                placeholder={"Select  Investment Size"}
                label={"Company Style"}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <p className="inter text-primary mb-1">
              Comment
              <span>*</span>
            </p>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: `Please input Description!`,
                },
              ]}
            >
              <Input.TextArea
                name="description"
                className="bg-themeGray-light inter"
                placeholder="Description"
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-center mt-4">
          <ThemeButton
            type={"submit"}
            content={loading ? <Spin /> : "Send Message"}
            dark
            className={"w-full !h-11"}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default PartnershipModal;

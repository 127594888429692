import { Container } from "../../components/components";
import {
  certificate_1,
  certificate_2,
  certificate_3,
  certificate_4,
  fan,
  fan_2,
  frame_cover,
  wind_graph,
  wind_graph_2,
} from "../../assets/images/images";
import { Col, Row } from "antd";
import { SmallWindData } from "../../constants/constants";

const SmallWindPower = () => {
  return (
    <Container selected={"services"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              Smal Wind Power
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <p className="text-primary text-2xl md:text-5xl mt-5 md:mt-0">
          Small wind power energy solution
        </p>
        <p className="inter text-primary mt-3 md:mt-8">
          The 1st Lotte World Tower, the tallest skyscraper in Korea and the 6th
          tallest in the world Installed on the roof of the top floor at 553
          meters to supply energy such as aviation flashing.
        </p>
        <ul className="list-disc text-primary inter ml-6 mt-4">
          <li>
            Rotor rotor blade connection, central axis of wind power generator.
          </li>
          <li>Blades blades The blades rotated by the wind.</li>
          <li>The same structure as the wing of a lift blade airplane.</li>
          <li>
            Increases acceleration by generating tension and enables rapid
            acceleration several times the wind speed.
          </li>
          <li>
            It transmits the necessary force when starting the drag blade and is
            suitable for various winds.
          </li>
          <li>Easy to change lift after acceleration of drag.</li>
        </ul>
        <div className="mt-8 flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 md:mr-10">
            <p className="text-primary text-center md:text-start text-4xl md:text-6xl">
              “ Small wind power energy solution “
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <img src={fan} alt="" width={"100%"} height={"auto"} />
          </div>
        </div>
        <p className="inter text-primary mt-8">
          The 2nd Lotte World Tower, the tallest skyscraper in Korea and the 6th
          tallest in the world Installed on the roof of the top floor at 553
          meters to supply energy such as aviation flashing.
        </p>
        <ul className="list-disc text-primary inter ml-6 mt-4">
          <li>
            Generator Generator A device that converts blade rotation into
            electrical energy.
          </li>
          <li>
            Even if the wind of the PMG (permane nt magnet) type generator stops
          </li>
          <li>
            Since there is no rotational resistance due to no load, the amount
            of energy recovery is excellent when the wind blows again without
            stopping for a certain period of time.
          </li>
          <li>
            Controller A device that charges/controls generated electric energy
            into a battery (rechargeable battery).
          </li>
          <li>
            Battery battery Stores generated electricity and supplies power to
            the required power source
          </li>
        </ul>
        <div className="mt-8 flex flex-col md:flex-row items-center justify-between">
          <div className="mt-4 md:mt-0">
            <img src={fan_2} alt="" width={"100%"} height={"auto"} />
          </div>
          <div className="w-full md:w-1/2 md:ml-10">
            <p className="text-primary mt-5 md:mt-0 text-center md:text-start text-4xl md:text-6xl">
              “ Small wind power energy solution “
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-10 bg-themeLightBlue">
        <div
          style={{
            backgroundImage: `url(${frame_cover})`,
          }}
          className="flex justify-center items-center h-48"
        >
          <p className="text-themeLightGreen2 text-2xl text-center md:text-5xl">
            Zero Energy + Zero Pollution + Green Technology
          </p>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-10">
        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
          {SmallWindData.map((e, i) => {
            return (
              <Col lg={8} md={12} sm={12} xs={24} key={i} className="mb-4">
                <div className="bg-white h-full p-7 flex flex-col items-center rounded-lg">
                  <p className="text-primary text-center text-3xl mb-4">
                    {e.title}
                  </p>
                  <p className="text-primary inter text-center">
                    {e.description}
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>
        <img
          src={wind_graph}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-4"
        />
        <img
          src={wind_graph_2}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-8"
        />
        <p className="mt-16 text-primary text-5xl text-center">Certificate</p>
        <Row className="mt-4" gutter={{ xs: 10, sm: 10, md: 15, lg: 20 }}>
          <Col span={12} className="mt-2 md:mt-4">
            <img src={certificate_1} width={"100%"} height={"auto"} alt="" />
          </Col>
          <Col span={12} className="mt-2 md:mt-4">
            <img src={certificate_2} width={"100%"} height={"auto"} alt="" />
          </Col>
          <Col span={12} className="mt-2 md:mt-4">
            <img src={certificate_3} width={"100%"} height={"auto"} alt="" />
          </Col>
          <Col span={12} className="mt-2 md:mt-4">
            <img src={certificate_4} width={"100%"} height={"auto"} alt="" />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SmallWindPower;

import { Container } from "../../components/components";
import { hydrogen_method_chart } from "../../assets/images/images";

const HydrogenMethod = () => {
  return (
    <Container selected={"reelcause"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              The Hydrogen Method
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <p className="text-primary text-2xl md:text-5xl mt-5 md:mt-0">
          Act on the Hydrogen Economy Fostering Plan and Safety Management
          (Hydrogen Act)
        </p>
        <p className="mt-6 text-primary inter">
          (1) Enforced on February 5, 2021 [Act No. 16942, enacted on February
          4, 2020]
        </p>
        <p className="mt-12 font-semibold text-primary inter">
          Attachment # Hydrogen Economy Promotion and Hydrogen Safety Management
          Act (law.go.kr)
        </p>
        <p className="mt-6 text-primary inter">
          On January 9, last year, at the National Assembly plenary session, the
          Hydrogen Economy Fostering and Hydrogen Safety Management Act was
          passed. Eight bills have been introduced before the bill is passed.
          Starting with the ‘Hydrogen Economy Act (draft)’ proposed by Rep. Lee
          Won-wook, Rep. Chae-ik Lee’s Hydrogen Economy Revitalization Act
          (draft), Rep. Gyu-hwan Kim’s Special Act for Fostering the Hydrogen
          Industry (draft), Rep. Young-seok Yoon’s Hydrogen Industry Promotion
          Act (draft), Rep. of the Hydrogen Society Formation Act (draft), Rep.
          Jeon Hyun-hee’s Hydrogen Fuel Safety Management and Business Act
          (draft), Rep. Park Young-sun’s Hydrogen Safety Management and Business
          Act (draft), and Representative Song Gap-seok’s Hydrogen Economy
          Fostering and Hydrogen Safety Management Act (draft) ), etc. If you
          look at the proposed legislation, it mainly focuses on the formation
          of a hydrogen society and safety management. It can be seen that both
          the opposition and opposition lawmakers agreed that the development of
          a hydrogen economy and safe hydrogen management are necessary. The
          enactment of the Hydrogen Act is meaningful in that it provides a
          legal basis for safety management of low-pressure hydrogen products
          and facilities that use hydrogen fuel, and promotes the hydrogen
          economy by law, not government policy.
        </p>
        <p className="mt-12 font-semibold text-2xl text-primary inter">
          Main contents of the Hydrogen Act and subordinate laws
        </p>
        <p className="mt-6 text-primary inter">
          The Hydrogen Act, which consists of a total of 62 articles, is
          entrusted with the Presidential Decree for 59 items, including
          qualifications for hydrogen specialized companies, and 43 items,
          including hydrogen equipment inspection standards, by the Ordinance of
          the Ministry of Trade, Industry and Energy. The Ministry of Commerce,
          Industry and Energy, Korea Energy Agency, Korea Gas Safety
          Corporation, Korea Gas Corporation, Chung-Ang University (research
          institute), Korea Gas Safety Corporation Gas Safety Research
          Institute, Hydrogen Convergence Alliance Promotion Team, Energy
          Technology Evaluation Institute, etc. A TF was formed with 20 experts
          from 8 organizations and after 5 discussions, a draft sub-law was
          prepared. After that, on September 28, the enactment of the hydrogen
          law enforcement ordinance and enforcement rules were legislated.
        </p>
        <p className="mt-6 text-primary inter">
          The subordinate statute stipulates ▲the promotion of hydrogen
          specialized companies ▲installation of hydrogen fuel supply facilities
          ▲the creation of a dedicated institution for the implementation of the
          hydrogen economy ▲hydrogen sales price ▲insurance purchase
          ▲certification standards, etc. It contains information on the
          systematic development of the hydrogen industry and safety management
          such as hydrogen products and facilities using hydrogen fuel.
        </p>
        <img
          src={hydrogen_method_chart}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        <p className="mt-6 text-primary inter">
          Our products applied in Table 3 are water electrolysis facilities,
          hydrogen extractors, and fuel cell facilities that use hydrogen
          directly. In the meantime, it was impossible to manufacture products
          because there were no safety regulations.
        </p>
      </div>
    </Container>
  );
};

export default HydrogenMethod;

import { Container } from "../../components/components";
import {
  aeroplane,
  aerospace,
  construction_field,
  construction_phase,
  electricity,
  electric_way,
  heat_storage_method,
  hydrogen_energy_frame,
  hydrogen_fusion,
  incentive_chart,
} from "../../assets/images/images";
import { HydrogenCells } from "../../constants/constants";

const HydrogenEnergy = () => {
  return (
    <Container selected={"services"} nested={"hydrogenEnergy"}>
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="my-6 lg:my-0 flex justify-center w-full">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              Hydrogen Energy
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <p className="text-primary text-2xl md:text-5xl">
          Attachment: Introduction of hydrogen fuel cell related technology and
          small wind power technology
        </p>
        <p className="inter text-primary mt-8">
          As a non-metallic element, it is the lightest element present on the
          earth. It is a colorless, tasteless, and odorless gas, and hydrogen is
          an element that is regarded as an important non-polluting energy
          source to replace coal and petroleum because it does not emit
          pollutants even if it is burned. Periodic Table of the Elements As the
          symbol of the element 1, there is one quantum in the nucleus and no
          neutrons. It exists as a hydrogen molecule (H2) in which two atoms are
          bonded and is composed of organic compounds such as water, propane,
          methanol, and natural gas. Hydrogen is the most common substance in
          the universe, and it is 14 times lighter than air, so it disperses
          quickly in the atmosphere and has little possibility of exploding, and
          its autoignition point is 575 degrees, higher than gasoline (500
          degrees). There are many ways to produce hydrogen, specifically
          expressing what was mentioned above, and there are many ways to use
          the produced hydrogen.
        </p>
        <img
          src={hydrogen_energy_frame}
          alt=""
          width={"100%"}
          className="mt-8"
        />
        {HydrogenCells.map((e, i) => {
          return (
            <div className="flex mt-8">
              <div className="border-2 border-primary"></div>
              <div className="ml-5">
                <p className="text-primary text-2xl mb-4">
                  {i + 1} {e.title}:
                </p>
                <p className="inter text-primary">{e.description}</p>
              </div>
            </div>
          );
        })}
        <p className="inter text-primary mt-8">
          That’s it. Method of decomposing natural gas at high temperature and
          high pressure (reformed hydrogen) In this water electrolysis
          technology, the most ideal and efficient way to create green hydrogen
          is to use the surplus power of renewable energy (solar power, wind
          power) to receive electrolysis (electrolysis). If hydrogen is produced
          and stored through ), the fuel cell can be used to obtain electricity
          again regardless of time and place, and hydrogen can have the meaning
          of sending electricity if it is delivered to another place through
          logistics. Hydrogen is not just a fuel cell that generates
          electricity, but hydrogen is effective in storing large-capacity
          power. Since renewable energy is a big energy source in the future,
          hydrogen storage can be seen as the meaning of electricity storage.
        </p>
        <p className="text-primary text-2xl mt-8 pb-2">
          Hydrogen related product development
        </p>
        {[
          "Hydrogen fuel cell power generation (household water electrolysis) hydrogen amplification system",
          "Air conditioner without outdoor unit by PCM heat storage method",
          "Aerospace (drone)",
          "Construction field (zero energy building)",
          "Container-Based Hydrogen Power Plant",
        ].map((e, i) => {
          return (
            <p className="text-primary mt-3 inter">
              {i + 1}) {e}
            </p>
          );
        })}
        <p className="text-primary text-2xl md:text-5xl mt-16">
          1) Hydrogen fuel cell power generation (household water electrolysis)
          hydrogen amplification system.
        </p>
        <p className="mt-6 md:mt-12 text-primary inter">
          After the 2011 Great East Japan Earthquake, the nuclear power plant
          entered the domestic fuel cell market in earnest. Japan, which started
          commercializing hydrogen fuel cells for eco-friendly households in
          2009, is leading the development of hydrogen energy by increasing the
          number of domestic fuel cells supplied by 100 times compared to 2009.
        </p>
        <p className="mt-8 text-primary inter font-semibold text-xl">
          What is a home fuel cell?
        </p>
        <p className="mt-4 text-primary inter">
          Japan, which began research on the commercialization of domestic fuel
          cells in the late 1990s, began selling domestic fuel cells to general
          consumers in May 2009. In Japan, household fuel cells are called
          Ene-Farm. This is a new term that combines energy + farm, and
          electricity is generated and additionally generated after a chemical
          reaction with hydrogen extracted from city gas or LP gas and oxygen in
          the air. It is a method of using as a boiler as a heat source.
          (According to the “2020 Energy White Paper” by the Ministry of
          Economy, Trade and Industry of Japan, Enerpharm, which had 3,000 units
          nationwide in 2009, distributed about 336,000 units in December 2019.
        </p>
        <div className="mt-8 flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 md:mr-10 mt-0 md:mt-8">
            <p className="text-primary inter font-semibold text-xl">
              Hydrogen energy fusion conversion device (small generator)
            </p>
            <p className="mt-4 text-primary inter">
              The hydrogen energy fusion converter is a device that increases
              the energy efficiency of 3.5 times (1KW) compared to the input
              energy through the primary conversion device of hydrogen and fuel
              cells and the secondary conversion device by new technology using
              300W of electric energy.
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <img src={hydrogen_fusion} alt="" width={"100%"} height={"auto"} />
          </div>
        </div>
        <p className="text-primary text-2xl md:text-5xl mt-16">
          2) Air conditioner without outdoor unit by heat storage method (PCM
          cooling, heat pipe + hydrogen fuel cell system)
        </p>
        <img
          src={heat_storage_method}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        <div className="mt-8 flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 md:mr-10 mt-0 md:mt-8">
            <p className="text-primary text-2xl">
              “ There is no external device line, so there is no separate
              construction / Daily electricity consumption = Refrigerator power
              level. “
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <img src={electricity} alt="" width={"100%"} height={"auto"} />
          </div>
        </div>
        <p className="text-primary text-2xl md:text-5xl mt-16">
          3) Aerospace (Drone) Former Russia R&D
        </p>
        <img
          src={aerospace}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-12"
        />
        <div className="mt-8 flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 md:mr-10 mt-0 md:mt-8">
            <p className="text-primary text-2xl">
              “ Development of commercial drone using hydrogen fuel cell-former
              Soviet Union technology alliance “
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <img src={aeroplane} alt="" width={"100%"} height={"auto"} />
          </div>
        </div>
        <p className="text-primary text-2xl md:text-5xl mt-16">
          4) Construction field (zero energy building)
        </p>
        <p className="mt-6 md:mt-12 text-primary inter">
          From the Kyoto Protocol in 1997 to the Paris Agreement on Climate
          Change, which was concluded last year, the issue of global warming due
          to greenhouse gas emissions is spreading all over the world, and
          policies to minimize fossil fuels and switch to renewable energy have
          become an inevitable trend. . Accordingly, Korea is also actively
          pursuing measures to reduce greenhouse gas emissions and expand the
          renewable energy market. In particular, as one of the new energy
          industries, the Zero Energy Building project was promoted to construct
          zero energy buildings for new buildings from 2025. Plan to make it
          mandatory.
        </p>
        <img
          src={construction_field}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-8"
        />
        <p className="mt-8 text-primary inter">
          There are two ways to realize such a zero-energy building, which can
          be said to be an “energy self-reliant building”: the passive method
          and the active method. First, in the case of the passive construction
          method, it is a construction method that significantly reduces heating
          energy while having excellent cost-effectiveness among the
          construction methods known so far.It uses a high-performance
          insulation material three times higher than that of general buildings
          to prevent heat from escaping, thus reducing heating energy by 80%
          compared to general buildings. It has a feature of saving. The active
          construction method refers to self-producing and supplying new and
          renewable energy by utilizing mechanical devices such as wind power,
          hydrogen energy, solar power, and geothermal heat in a building.
        </p>
        <img
          src={electric_way}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-8"
        />
        <p className="mt-8 text-primary inter">
          The passive method and the active method are applied together, but
          even if the areas of the two methods are different, the active method
          will be adopted as the main energy source based on the application of
          the passive method to realize the most efficient and economical
          zero-energy building. In addition, as an additional element necessary
          to realize a zero-energy building, there is the Building Energy
          Management System (BEMS), a system that monitors the energy
          consumption of the building in real time and optimizes the collected
          energy use level to manage the most efficiently. However, BEMS is said
          to have the greatest effect when applying the active method as its
          main energy support because there is a limit to saving energy with its
          own system. In the case of Korea, it is planning to expand the market
          through activation of zero-energy buildings by step-by-step mandatory
          by 2025.
        </p>
        <img
          src={construction_phase}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-8"
        />
        <p className="mt-8 text-primary inter">
          In particular, when a zero-energy building is commercialized, it is
          possible to reduce annual energy costs by up to 80%, and it is
          possible to develop new markets through overseas expansion through the
          development of new technologies in the construction field. In
          addition, if 70% of new buildings become zero-energy buildings in
          2030, we can expect to reduce greenhouse gas emissions and create
          100,000 jobs per year.
        </p>
        <p className="mt-8 text-primary text-2xl">
          Zero-energy building certification system (transformed into an
          energy-efficient first-class building)
        </p>
        <p className="mt-4 text-primary inter">
          Green buildings that minimize the energy load required for buildings
          and use new and renewable energy to minimize energy consumption,
          ranging from grade 1 to grade 5 according to the energy independence
          rate (if the total energy reduction rate is 40% or more, the first
          grade, 30% or more) Less than 40% is a 2nd grade, 20% or more and less
          than 30% is a 3rd grade, 10% or more and less than 20% is a 4th grade,
          and 0% or more and less than 10% is a 5th grade). Since the late
          1980s, Europe and the United States have created a passive house
          certification system as part of their energy conservation policy, and
          through this, energy savings of 85% to 90% compared to general
          buildings have been induced, and energy certificates have been
          submitted to all new construction and renovations since 2012 The
          regulations have been changed and the law has been refining since
          2015. As this certification system became popular, it started in
          Europe, and the UK is targeting all residential sectors from 2016 to
          2020, all federal buildings by 2030, and commercial buildings by 2050
          as zero-energy buildings.
        </p>
        <p className="mt-8 text-primary text-2xl">
          Zero Energy Building Incentive Chart
        </p>
        <img
          src={incentive_chart}
          width={"100%"}
          height={"auto"}
          alt=""
          className="mt-4 md:mt-8"
        />
        <p className="mt-4 text-primary inter">
          Green building incentives include acquisition tax, property tax, and
          building standard benefits based on building energy efficiency level,
          green building certification, and zero energy building certification.
          In addition, projects that support interest on loans according to the
          rate of performance improvement through green remodeling and projects
          that support construction costs for public buildings are being carried
          out. In terms of real estate value, the value of green architecture is
          reflected in the “practices for appraisal of appraisals”. In December
          2016, it was revised so that the appraisal of the real estate
          valuation guideline, the appraisal practice standard, was revised to
          allow appraisal including the increase in the value of eco-friendly
          facilities and energy efficiency facilities such as facilities for
          reducing greenhouse gas emissions and facilities using new and
          renewable energy. In addition, the new building unit price table is
          also revised so that more expensive unit prices can be applied in the
          case of green buildings. Green building certification buildings can
          add up to 8.45% of total construction cost according to their grade
          and use. In addition to this, it supports to act as a psychological
          value critic in the market by including contents related to green
          buildings in real estate information. Green certification and energy
          performance indicators, etc., are required to be recorded in the
          building register along with the expiration date, and are open to the
          real estate portal so that anyone can read. In addition, apartment
          houses with more than 300 households and business facilities with a
          total floor area of ​​3,000 ㎡ or more must disclose the energy
          evaluation form.
        </p>
      </div>
    </Container>
  );
};

export default HydrogenEnergy;

import { Button } from "antd";

const ThemeButton = ({
  content,
  className,
  onlyBorder,
  dark,
  darkBorder,
  onClick,
  orange,
  href,
  target,
  type,
}) => {
  return (
    <Button
      htmlType={type}
      target={target}
      href={href}
      onClick={onClick}
      className={`${className} ${
        onlyBorder
          ? darkBorder
            ? "!border-primary bg-transparent !text-primary"
            : "!border-themeGreen bg-transparent !text-themeGreen"
          : dark
          ? "bg-primary !text-themeGreen"
          : orange
          ? "bg-themeOrange text-primary !border-none"
          : "bg-themeGreen text-primary !border-none"
      } rounded-full w-32 h-9`}
    >
      {content}
    </Button>
  );
};

export default ThemeButton;

import { Input } from "antd";

const ThemeInput = ({ label, placeholder, addonBefore, ...props }) => {
  return (
    <div>
      <p className="inter text-primary mb-1">
        {label}
        <span>*</span>
      </p>
      <Input
        addonBefore={addonBefore}
        className="bg-themeGray-light inter focus:!shadow-none h-9"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default ThemeInput;

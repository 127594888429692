import { useState } from "react";
import {
  Container,
  InvestorModal,
  PartnershipModal,
  ServicesCard,
  ThemeButton,
} from "../../components/components";
import {
  cloud,
  down_outlined_arrow,
  otcmarkets,
  reelcause,
  right_black_icon,
  star,
  sun,
  filled_star,
  sketch_annotation,
  filled_star_2,
  right_arrow_icon,
  arrow_icon,
  filled_green_star,
} from "../../assets/icons/icons";
import { kbs2024 } from "../../assets/videos/videos";
import {
  association_tag,
  hhoen,
  hienergy_logo,
  home_frame,
  hypower_logo,
  imagincreate_cover,
  profile_image,
  // reelcausebio_logo,
  reelcauseMEnergy,
  reelcauseenergy_logo,
} from "../../assets/images/images";
import { Col, Input, Row } from "antd";
import { ServicesData } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Home = () => {
  const [investorModal, setInvestorModal] = useState(false);
  const [partnershipModal, setPartnershipModal] = useState(false);
  const navigate = useNavigate();
  return (
    <Container>
      <InvestorModal
        isModalOpen={investorModal}
        onClose={() => setInvestorModal(false)}
      />
      <PartnershipModal
        isModalOpen={partnershipModal}
        onClose={() => setPartnershipModal(false)}
      />
      <div className="flex flex-col items-center bg-primary">
        <div className="flex flex-col lg:flex-row items-center lg:items-stretch justify-between w-full px-3 lg:px-16 md:px-12 sm:px-7 py-4">
          <div className="flex flex-col justify-around items-center">
            <div>
              <img src={star} alt="" />
            </div>
            <img
              src={cloud}
              alt=""
              className="opacity-50 w-32 lg:w-44 mt-7 lg:mt-0"
            />
          </div>
          <div className="my-6 lg:my-0">
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              Hydrogen
            </p>
            <p className="text-6xl lg:text-8xl text-center text-themeGreen">
              Is Not The Future
            </p>
            <p className="text-themeLightGreen2 text-6xl lg:text-8xl text-center">
              It Is The Present
            </p>
            <div className="flex justify-center mt-3">
              <ThemeButton
                onClick={() => setInvestorModal(true)}
                className={"px-5 h-12 w-36"}
                content={
                  <div
                    className="flex items-center justify-between"
                    style={{ marginTop: 1 }}
                  >
                    <p>Investor</p>
                    <img src={right_black_icon} alt="" width={12} />
                  </div>
                }
              />
              <ThemeButton
                orange
                onClick={() => setPartnershipModal(true)}
                className={"ml-6 px-5 h-12 w-36"}
                content={
                  <div
                    className="flex items-center justify-between"
                    style={{ marginTop: 1 }}
                  >
                    <p>Partnership</p>
                    <img src={right_black_icon} alt="" width={12} />
                  </div>
                }
              />
            </div>
          </div>
          <div className="flex flex-col justify-around items-center">
            <div>
              <img
                src={sketch_annotation}
                alt=""
                className="lg:-mt-20 -mt-10"
              />
            </div>
            <img
              src={cloud}
              alt=""
              width={160}
              className="opacity-50 hidden lg:block -mt-20"
            />
          </div>
        </div>
        <div className="w-full flex lg:block justify-between items-center px-4 lg:px-0 -mt-14 lg:-mt-0">
          <div className="lg:relative lg:w-full">
            <img
              src={filled_green_star}
              alt=""
              className="lg:absolute top-20 left-40"
            />
          </div>
          <div className="lg:relative lg:w-full hidden lg:block">
            <img src={sun} alt="" className="lg:absolute top-24 right-40" />
          </div>
          <div className="lg:flex justify-center my-5 lg:mb-0 lg:mt-7">
            <img src={down_outlined_arrow} width={35} alt="" className="my-3" />
          </div>
          <div className="lg:relative lg:w-full">
            <img src={star} alt="" className="lg:absolute -top-10 right-40" />
          </div>
        </div>
        <img src={home_frame} alt="" width={"100%"} height={"auto"} />
      </div>
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col lg={12} md={12} sm={24} xs={24} className="mt-6">
            <div
              className="bg-themeDarkGreen2 rounded-lg justify-center h-72 flex flex-col items-center"
              style={{ minHeight: 224 }}
            >
              <img src={reelcause} width={220} alt="" />
              <p className="inter text-themeDarkGreen3 text-center my-5 px-7">
                <span className="font-bold">Meaning of symbol:</span> In
                definition, Reelcause means finding the real meaning of causes
                in the world. This can be environmental issues, life disasters,
                etc.
              </p>
              <ThemeButton dark content={"Visit now"} />
            </div>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24} className="mt-6">
            <div
              className="bg-themeLightBlue rounded-lg justify-center h-72 flex flex-col items-center"
              style={{ minHeight: 224 }}
            >
              <img src={otcmarkets} width={220} alt="" />
              <p className="inter text-themeDarkGreen3 text-center my-5 px-7">
                REELCAUSE was listed on OTC PINK on December 16, 2016.
              </p>
              <div className="rounded-full w-32 h-9 bg-primary text-themeGreen flex justify-center items-center">
                <a
                  target={"_blank"}
                  href={"https://www.otcmarkets.com/stock/RCIT/overview"}
                  className="hover:!text-themeGreen"
                >
                  Visit now
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div className="flex justify-center">
          <div className="mt-16 w-[80%] flex flex-col lg:flex-row items-center justify-around">
            <div className="text-center">
              <img src={profile_image} alt="" width={400} />
              <p className="text-primary text-5xl mt-3">WOODUCK LEE</p>
              <p className="inter text-primary text-sm -mt-1 font-semibold">
                CEO
              </p>
              <p className="inter text-primary text-sm -mt-[2px] font-semibold">
                ReelCause
              </p>
            </div>
            <div className="w-full lg:w-[40%] text-center mt-6 mb-10 lg:my-0">
              <p className="text-5xl lg:text-7xl text-primary">
                Greeting From Our CEO
              </p>
              <p className="inter mt-5 leading-6">
                Through a cooperation agreement for small wind power generation
                based on new and renewable energy technology, we will develop
                eco-friendly new and renewable energy technology based on green
                hydrogen energy of the water electrolysis method and enter the
                global market. Based on non-depleting energy and eco-friendly
                clean green hydrogen energy, we will develop a mutually
                complementary and sustainable business.
              </p>
              <ThemeButton
                content={"Learn more"}
                onlyBorder
                darkBorder
                className={"mt-5"}
                onClick={() => navigate("/about")}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-around">
          <img src={filled_star} alt="" className="md:mt-20" />
          <div className="flex flex-col items-center">
            <img src={sketch_annotation} alt="" />
            <p className="text-primary text-6xl -mt-5">Our Service</p>
            <p className="inter w-3/4 text-center mt-1">
              Our services collectively represent a comprehensive range of
              offerings related to small wind power generation, hydrogen energy,
              and their application in various sectors such as agriculture and
              aerial technology.
            </p>
          </div>
          <img src={filled_star} alt="" className="mt-10 md:mt-20" />
        </div>
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 45 }}>
          {ServicesData.map((e, i) => {
            return (
              <Col lg={12} md={12} sm={24} xs={24} className="mt-10">
                <ServicesCard
                  onClick={() => navigate(e.navigate)}
                  title={e.title}
                  image={e.image}
                  description={e.description}
                />
              </Col>
            );
          })}
        </Row>
        <div className="mt-14 flex flex-col items-center">
          <p className="text-primary text-4xl">Company Alliance</p>
          <Row
            a
            gutter={{ xs: 20, sm: 32, md: 40, lg: 60 }}
            className="mt-4"
            style={{
              alignItems: "center",
            }}
          >
            {[
              hienergy_logo,
              // reelcausebio_logo,
              reelcauseMEnergy,
              reelcauseenergy_logo,
              hypower_logo,
            ].map((e, i) => {
              return (
                <Col lg={6} md={6} sm={12} xs={12} className="mt-4">
                  <img src={e} alt="" width={200} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <Row className="bg-primary px-3 lg:px-16 md:px-12 sm:px-7 py-14 flex items-center md:mt-0 mt-8">
        <Col lg={12} md={12} sm={24} xs={24}>
          <p className="text-themeLightGreen2 text-6xl">Imagine and Create</p>
          <p className="inter text-themeLightGreen3 my-7 w-4/5">
            reelcause R&D is here to provide reliable and sustainable products
            for people across the country and around the world.reelcause R&D has
            been constantly improving and innovating products for our customers.
          </p>
          <ThemeButton
            onClick={() => navigate("hydrogen-energy")}
            content={"Learn more"}
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24} className="mt-10 md:mt-0">
          <img src={imagincreate_cover} alt="" />
        </Col>
      </Row>
      {/* 2024 */}
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <div className="flex flex-wrap flex-col lg:flex-row justify-between items-center">
          <div className="">
            <img
              src={
                "https://cdn.ces.tech/ces/media/logos-and-icons/2022/cescta-logo.png"
              }
              alt=""
            />
            <p className="mt-6 text-primary text-4xl w-full lg:w-2/3">
              REELCAUSE R&D, Innovation award at CES 2024 for Hydrogne
              HYDROLYSIS HHO Burner
            </p>
          </div>
          <div className="flex flex-col items-center w-full lg:w-1/3 mt-4 lg:mt-0">
            <img src={filled_star} alt="" />
            <p className="mt-6 inter text-primary">
              Innovation Award at CES2024
            </p>
          </div>
        </div>
        <div className="mt-12 flex flex-wrap justify-center gap-5">
          <ReactPlayer
            width={"60%"}
            height={500}
            // url="https://youtu.be/uV9LRfaxSQU?si=ayOiC655OXE1aW3T&t=666"
            url={kbs2024}
            controls={true}
          />

          <div className="flex-1 min-w-[300px]">
            <img src={hhoen} alt="" />
          </div>
        </div>

        <div className="mt-12 flex justify-center">
          <ReactPlayer
            width={"60%"}
            height={500}
            url="https://youtu.be/uV9LRfaxSQU?si=ayOiC655OXE1aW3T&t=666"
            controls={true}
          />
        </div>
      </div>
      {/* 2023 */}
      <div className="px-3 lg:px-16 md:px-12 sm:px-7 py-4 md:py-16">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="">
            <img src={association_tag} alt="" />
            <p className="mt-6 text-primary text-4xl w-full lg:w-2/3">
              REELCAUSE R&D, Green Hydrogen Renewable Energy Attention at CES
              2023
            </p>
          </div>
          <div className="flex flex-col items-center w-full lg:w-1/3 mt-4 lg:mt-0">
            <img src={filled_star} alt="" />
            <p className="mt-6 inter text-primary">
              A video of Gary J Shapiro(President/CEO, Consumer Electronics
              Association) coming to our booth at ces & listening to
              explanations. add more copy about this section. like video brief.
            </p>
          </div>
        </div>
        <div className="mt-12 flex justify-center">
          <ReactPlayer
            width={"60%"}
            height={500}
            url="https://www.youtube.com/watch?v=aAW0xeBBrdU&t=14s"
            controls={true}
          />
        </div>
        {/* <Row className="bg-primary rounded-lg px-7 py-6 min-h-96 sm:h-96 mt-14 flex flex-col sm:flex-row items-center justify-between">
          <Col lg={7}>
            <img
              src={filled_star_2}
              alt=""
              className="opacity-50 mb-10 mt-3 block md:hidden"
            />
            <img
              src={filled_star_2}
              alt=""
              className="opacity-50 hidden md:block mb-24"
            />
            <img
              src={cloud}
              alt=""
              className="opacity-50 hidden md:block mb-24"
              width={150}
            />
          </Col>
          <Col lg={10}>
            <div>
              <p className="text-themeLightGreen2 text-5xl sm:text-6xl text-center">
                For any inquiries? <br /> please email
              </p>
              <div className="relative">
                <img
                  src={arrow_icon}
                  className="absolute right-0 -bottom-7"
                  alt=""
                  width={50}
                />
              </div>
            </div>
            <Input
              className="rounded-full pl-5 pr-1 mt-14"
              style={{ paddingTop: 5 }}
              placeholder="Enter your email"
              suffix={
                <ThemeButton
                  dark
                  content={
                    <div className="flex items-center justify-between">
                      <p className="mt-1">Send Mail</p>
                      <div className="bg-themeGreen h-6 w-6 flex justify-center items-center rounded-full">
                        <img src={right_arrow_icon} alt="" width={10} />
                      </div>
                    </div>
                  }
                />
              }
            />
          </Col>
          <Col lg={7}>
            <img
              src={filled_star_2}
              alt=""
              className="opacity-50 mt-10 mb-3 block md:hidden"
            />
            <div className="flex-col items-end hidden md:flex">
              <img src={sun} alt="" className="mb-24 opacity-50" width={100} />
              <img src={filled_star_2} alt="" className="mb-24 mr-20" />
            </div>
          </Col>
        </Row> */}
      </div>
    </Container>
  );
};

export default Home;
